<template>
  <BasePage :hideLeft="true" :hideBanner="true">
    <ContentView :haveFile="false" :haveTurner="false" />
  </BasePage>
</template>

<script>
import { reactive, computed } from "vue";
import BasePage from "@/components/BasePage/BasePage.vue";
import ContentView from "./components/ContentView/ContentView.vue";
import { mainStore, stateStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  components: { BasePage, ContentView },
  setup() {
    const storeOfstate = stateStore();
    const contentData = computed(() => {
      return storeToRefs(storeOfstate).activeHtml.value;
    });
    const contentTitle = computed(() => {
      return storeToRefs(storeOfstate).activeTitle.value;
    });

    const data = reactive({
      contentData,
      contentTitle,
    });

    return {
      ...data,
    };
  },
};
</script>

<style scoped>

</style>

